import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import Features from "./../../components/Features";
import { Heading4 } from "./../../components/Typography";

const FlexFeatures = ({ block, index }) => {
  return (
    <Container
      id="section-features"
      pt={["", "7.5rem"]}
      pb={["2.875rem", "4.5rem"]}
    >
      <Inner isSmall>
        <Section
          ptBody={["", "2.5rem"]}
          pbBody={["1rem", "5rem"]}
          footer={
            <Heading4
              as="h2"
              textAlign="center"
              fontWeight="300"
              dangerouslySetInnerHTML={{ __html: block.description }}
            />
          }
        >
          <Features items={block.iconsWithDescription} />
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexFeatures;
