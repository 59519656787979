import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import { Typo10 } from "./../../components/Typography";

const FlexFullWidthText = ({ block, index }) => {
  return (
    <Container pt={["40px", "70px"]}>
      <Inner isSmall>
        <Section
          heading={<Typo10 textAlign={["left", "center"]}>{block.text}</Typo10>}
        ></Section>
      </Inner>
    </Container>
  );
};

export default FlexFullWidthText;
