import styled from 'styled-components';

import { media } from './../../common/MediaQueries';

const HeroBannerStyled = styled.div`
  height: 272px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  @media ${media.phone} {
    height: 500px;
  }
`;

const HeroBannerWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export { HeroBannerStyled, HeroBannerWrapperStyled };
