import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import TextColumn from "./../../components/TextColumn";

const FlexTextColumnAlt = ({ block, index }) => {
  return (
    <Container pt={["30px", "0"]} pb={["", "60px"]}>
      <Inner isSmall>
        <Section>
          <TextColumn
            key={index}
            isRotated={block.reverseElements !== null ? true : false}
            heading={block.heading}
            text={block.text}
            ctaUrl={block.button !== null ? block.button.url : ""}
            ctaText={block.button !== null ? block.button.title : ""}
          />
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexTextColumnAlt;
