import styled, { css } from 'styled-components';

import { transition } from './../../common/mixins';
import { media } from './../../common/MediaQueries';

const HeroNavWrapperStyled = styled.div`
  width: 100%;
`;

const HeroNavStyled = styled.div`
  display: flex;
  height: 270px;

  @media ${media.phone} {
    height: 618px;
  }
`;

const HeroNavItemContentWrapStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  @media ${media.phone} {
    width: 352px;
    height: 352px;
  }
`;

const HeroNavItemMaskStyled = styled.div`
  ${transition};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    width: 238px;
    height: 238px;
    border-radius: 50%;
    border: 64px solid ${({ theme }) => theme.colors.white};
    background: transparent;
    opacity: 0.15;

    @media ${media.phone} {
      border: 88px solid ${({ theme }) => theme.colors.white};
      opacity: 0.2;
      width: 100%;
      height: 100%;
    }
  }
`;

const HeroNavItemContentStyled = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;
  padding: 0 1rem;
`;

const HeroNavItemContentHeadStyled = styled.h2`
  font-size: 30px;
  line-height: 1;
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 1rem;

  @media ${media.phone} {
    font-size: 50px;
  }
`;

const HeroNavItemContentSubheadStyled = styled.p`
  font-size: 16px;
  line-height: 1.1;
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};

  @media ${media.phone} {
    font-size: 28px;
  }
`;

interface HeroNavItemStyledProps {
  isHire?: boolean;
  isGetHired?: boolean;
}

const HeroNavItemStyled = styled.a<HeroNavItemStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    ${HeroNavItemMaskStyled} {
      opacity: 0.4;
    }
  }

  ${({ isHire }) =>
    isHire &&
    css`
      background-image: linear-gradient(180deg, #012147 0%, #03457d 100%);

      ${HeroNavItemContentHeadStyled} {
        color: ${({ theme }) => theme.colors.watermelon};
      }

      ${HeroNavItemActionStyled} {
        @media ${media.phone} {
          background-image: linear-gradient(137deg, #012147 0%, #38b1eb 100%);
        }
      }
    `}

  ${({ isGetHired }) =>
    isGetHired &&
    css`
      background-image: linear-gradient(180deg, #ff88a9 0%, #ff5070 100%);
      ${HeroNavItemContentHeadStyled} {
        color: ${({ theme }) => theme.colors.main};
      }
      ${HeroNavItemActionStyled} {
        @media ${media.phone} {
          background-image: linear-gradient(180deg, #ff5070 0%, #ff88a9 100%);
        }
      }
    `}
`;

const HeroNavItemActionStyled = styled.div`
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 36.5px;
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;

  /* &:hover + * {
    opacity: 0.4;
  } */
  svg {
    width: 34px;
    @media ${media.phone} {
      width: 48px;
    }
  }
  @media ${media.phone} {
    bottom: calc(-50px / 2);
    width: 50px;
    height: 50px;
  }
`;

export {
  HeroNavWrapperStyled,
  HeroNavStyled,
  HeroNavItemStyled,
  HeroNavItemContentStyled,
  HeroNavItemActionStyled,
  HeroNavItemContentWrapStyled,
  HeroNavItemContentHeadStyled,
  HeroNavItemContentSubheadStyled,
  HeroNavItemMaskStyled,
};
