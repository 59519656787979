import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const GetStartedArrow: SvgComponent = (props) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M38 25a13 13 0 11-13-13 13 13 0 0113 13zm-2.51 0A10.49 10.49 0 1025 35.49 10.49 10.49 0 0035.49 25zM24 18.29h2.1a.63.63 0 01.63.63V25h3.51a.63.63 0 01.45 1.07l-5.19 5.2a.65.65 0 01-.9 0l-5.19-5.2a.63.63 0 01.4-1.07h3.51v-6.08a.63.63 0 01.68-.63z"
      fill="#fff"
    />
  </svg>
);

export default GetStartedArrow;
