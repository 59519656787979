import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import Testimonials from "./../../components/Testimonials";
import FormSection from "./../../components/FormSection";
import HireForm from "./../../components/Forms/HireForm";
import Divider from "./../../components/Divider";
import TESTIMONIALS_SLIDER_CFG from "./../../constants/sliders/testimonialsCfg";

const FlexHireBgBlock = ({ block, index }) => {
  return (
    <Inner noOffsetMobile>
      <Container
        color="white"
        backgroundImage="linear-gradient(180deg, #012147 0%, #03457D 100%)"
      >
        <Container pt={["40px", "50px"]} pb={["24px", "26px"]}>
          <Section>
            <Inner isSmall>
              <Testimonials
                items={block.testimonials}
                settings={TESTIMONIALS_SLIDER_CFG}
              />
            </Inner>
          </Section>
        </Container>
        <Inner isSmall>
          <Divider isWhite isMobileFullwidth />
        </Inner>
        <Container pt={["40px", "40px", "0px"]} pb={["40px", "50px"]}>
          <Inner isSmall>
            <Section>
              <FormSection isFormFullWidth>
                <HireForm />
              </FormSection>
            </Section>
          </Inner>
        </Container>
      </Container>
    </Inner>
  );
};

export default FlexHireBgBlock;
