import React from "react";

import HeroBanner from "./../../components/HeroBanner";

const FlexHeroBanner = ({ block, index }) => {
  const imageDesktop =
    block.imageDesktop !== null ? block.imageDesktop.imageFile : "";

  const imageMobile =
    block.imageMobile !== null
      ? block.imageMobile.imageFile
      : block.imageDesktop !== null
      ? block.imageDesktop.imageFile
      : "";

  return (
    <HeroBanner
      image={[imageMobile, imageDesktop]}
      // image={[
      //   imageMobile,
      //   {
      //     ...imageDesktop,
      //     media: `(min-width: 768px)`,
      //   },
      // ]}
    />
  );
};

export default FlexHeroBanner;
