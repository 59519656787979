import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import HireFeatures from "./../../components/HireFeatures";
import { IFeature } from "../../interfaces/IFeature";

interface Props { 
  block: { 
    heading: string 
    description: string
    iconsWithDescription: IFeature[]
  }
}

const FlexHireFeatures = ({ block }: Props) => {
  return (
    <Container pt={["54px", "105px"]}>
      <Inner isSmall>
        <Section id="section-hire">
          <HireFeatures
            hired
            heading={block.heading}
            desc={block.description}
            items={block.iconsWithDescription}
          />
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexHireFeatures;
