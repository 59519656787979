import styled from 'styled-components';

import { customMedia, media } from './../../common/MediaQueries';
import {
  InfoBoxIconStyled,
  InfoBoxIconWrapperStyled,
  InfoBoxHeadingStyled,
  InfoBoxDescStyled,
} from './../Infobox/styles';
import { WysiwygStyled } from './../Wysiwyg/styles';

import {
  flex,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  alignContent,
  FlexboxProps,
  padding,
  width,
  maxWidth,
} from 'styled-system';

type IFlexboxProp = FlexboxProps;

const TimelineBlockStyled = styled('div')<IFlexboxProp>`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  ${flex}
  ${flexDirection}
  ${alignItems}
  ${alignContent}
  ${justifyContent}
  ${flexWrap}
  ${padding}
  ${width}
`;

const TimelineBlockItemStyled = styled('div')<IFlexboxProp>`
  display: flex;
  ${flex}
  ${flexWrap}
  ${padding}
  ${width}
  ${maxWidth}
`;

const TimelineHeadingStyled = styled.h2`
  font-size: 40px;
  line-height: 1;
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;

  @media ${media.phone} {
    text-align: left;
  }

  span {
    display: block;
    font-size: 25px;
    line-height: 1.4;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    margin-top: 10px;
    @media ${media.phone} {
      margin-top: 20px;
    }
  }

  @media ${media.phone} {
    font-size: 46px;
    span {
      font-size: 30px;
      line-height: 1.2;
    }
  }
`;

const TimelineDescStyled = styled.div`
  opacity: 0.7;
  ${WysiwygStyled} {
    *:not([class]) {
      font-size: 14px;
      font-weight: ${({ theme }) => theme.fontWeight.light};
      color: currentColor;
    }
  }
`;

const TimelineContainerStyled = styled.div`
  display: flex;
  width: 100%;

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

const TimelineContainerListStyled = styled.div`
  width: 100%;
  order: 2;
  margin-left: 52px;

  @media ${media.tablet} {
    order: 1;
    margin: 0;
    padding-bottom: 8px;
  }
`;

const TimelineContainerLineStyled = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  order: 1;

  @media ${media.tablet} {
    width: 100%;
    height: 1px;
    order: 2;
  }
`;

const TimelineFooterStyled = styled.div`
  position: relative;
  text-transform: uppercase;

  @media ${media.tablet} {
    text-align: center;
    margin: 60px auto 0;
  }

  &::before {
    @media ${media.tablet} {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -50px;
      width: 1px;
      background-color: white;
      height: 34px;
    }
  }
`;

const TimelineListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  @media ${media.tablet} {
    flex-direction: row;
    margin: 0 -25px;
  }
`;

const TimelineListItemStyled = styled.li`
  max-width: 100%;
  flex-basis: 100%;

  @media ${media.tablet} {
    padding: 0 25px;
    max-width: 25%;
    flex-basis: 25%;
    margin-bottom: 0px;
    padding: 0 25px;
  }
`;

const TimelineItemStyled = styled.div`
  position: relative;
  ${width};
  ${padding};
  height: 100%;
  margin-bottom: 60px;

  &::after {
    content: '';
    position: absolute;
    left: -52px;
    top: 105px;
    width: 34px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    @media ${media.tablet} {
      left: 50%;
      top: auto;
      //bottom: -60px;
      bottom: 0;
      width: 1px;

      height: 34px;
    }
  }
  ${InfoBoxIconStyled} {
    @media ${customMedia.maxPhone} {
      margin: 0;
    }
  }
  ${InfoBoxIconWrapperStyled} {
    @media ${customMedia.maxPhone} {
      margin: 0 0 28px;
    }
  }
  ${InfoBoxHeadingStyled} {
    @media ${customMedia.maxPhone} {
      text-align: left;
    }
  }
  ${InfoBoxDescStyled} {
    @media ${customMedia.maxPhone} {
      text-align: left;
    }
  }
`;

export {
  TimelineBlockStyled,
  TimelineBlockItemStyled,
  TimelineHeadingStyled,
  TimelineDescStyled,
  TimelineItemStyled,
  TimelineContainerStyled,
  TimelineContainerListStyled,
  TimelineContainerLineStyled,
  TimelineFooterStyled,
  TimelineListStyled,
  TimelineListItemStyled,
};
