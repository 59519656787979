import React from "react";

import {
  HeroNavWrapperStyled,
  HeroNavStyled,
  HeroNavItemStyled,
  HeroNavItemContentStyled,
  HeroNavItemActionStyled,
  HeroNavItemContentWrapStyled,
  HeroNavItemContentHeadStyled,
  HeroNavItemContentSubheadStyled,
  HeroNavItemMaskStyled,
} from "./styles";

import ScrollDownIcon from "./../../assets/svg/GetStartedArrow";

interface IProps {
  scrollTarget?: string;
  getHiredHeading?: string;
  getHiredDesc?: string;
  getHiredLink?: string;
  hireHeading?: string;
  hireDesc?: string;
  hireLink?: string;
}

const HeroNav: React.FC<IProps> = ({
  getHiredHeading,
  getHiredDesc,
  getHiredLink,
  hireHeading,
  hireDesc,
  hireLink,
}) => {
  return (
    <HeroNavWrapperStyled>
      <HeroNavStyled>
        <HeroNavItemStyled isHire aria-label="Scroll down" href={hireLink}>
          <HeroNavItemContentWrapStyled>
            <HeroNavItemContentStyled>
              <HeroNavItemContentHeadStyled>
                {hireHeading}
              </HeroNavItemContentHeadStyled>
              <HeroNavItemContentSubheadStyled>
                {hireDesc}
              </HeroNavItemContentSubheadStyled>
            </HeroNavItemContentStyled>
            <HeroNavItemActionStyled>
              <ScrollDownIcon />
            </HeroNavItemActionStyled>
            <HeroNavItemMaskStyled />
          </HeroNavItemContentWrapStyled>
        </HeroNavItemStyled>
        <HeroNavItemStyled
          isGetHired
          aria-label="Scroll down"
          href={getHiredLink}
        >
          <HeroNavItemContentWrapStyled>
            <HeroNavItemContentStyled>
              <HeroNavItemContentHeadStyled>
                {getHiredHeading}
              </HeroNavItemContentHeadStyled>
              <HeroNavItemContentSubheadStyled>
                {getHiredDesc}
              </HeroNavItemContentSubheadStyled>
            </HeroNavItemContentStyled>
            <HeroNavItemActionStyled>
              <ScrollDownIcon />
            </HeroNavItemActionStyled>
            <HeroNavItemMaskStyled />
          </HeroNavItemContentWrapStyled>
        </HeroNavItemStyled>
      </HeroNavStyled>
    </HeroNavWrapperStyled>
  );
};

export default HeroNav;
