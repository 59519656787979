import React from "react";
import { HeroHomeContainerStyled } from "../../components/HeroHome/styles";
import { getHired } from "../../mocks/get-hired";
import { hire } from "../../mocks/hire";

import HeroNav from "./../../components/HeroNav";

const FlexHeroNav = ({ block, index }) => {
  return (
    <HeroNav
      getHiredHeading={block.getHired.heading}
      getHiredDesc={block.getHired.description}
      getHiredLink={block.getHired.scrollSection}
      hireHeading={block.hire.heading}
      hireDesc={block.hire.description}
      hireLink={block.hire.scrollSection}
    />
  );
};

export default FlexHeroNav;
