import React from "react";

import {
  BiosItemStyled,
  BiosItemMediaStyled,
  BiosItemContentStyled,
  BiosItemContentHeadStyled,
  BiosItemContentHeadNameStyled,
  BiosItemContentHeadTitleStyled,
  BiosItemContentBodyStyled,
} from "./styles";
import { Typo2, Typo7 } from "./../../components/Typography";
import theme from "./../../themes/theme";
import Image from "./../Image";

const BiosItem = ({ fullName, description, text, image }) => {
  return (
    <BiosItemStyled>
      <BiosItemMediaStyled>
        {image.src ? (
          <Image fluid={["", image]} alt={`${fullName} photo`} />
        ) : (
          <Image fluid={["", image.imageFile]} alt={`${fullName} photo`} />
        )}
      </BiosItemMediaStyled>
      <BiosItemContentStyled>
        <BiosItemContentHeadStyled>
          <BiosItemContentHeadNameStyled>
            <Typo7 fontWeight={theme.fontWeight.bold}>{fullName}</Typo7>
          </BiosItemContentHeadNameStyled>
          <BiosItemContentHeadTitleStyled>
            <Typo2 fontWeight={theme.fontWeight.light}>{description}</Typo2>
          </BiosItemContentHeadTitleStyled>
        </BiosItemContentHeadStyled>
        <BiosItemContentBodyStyled>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </BiosItemContentBodyStyled>
      </BiosItemContentStyled>
    </BiosItemStyled>
  );
};

export default BiosItem;
