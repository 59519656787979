import React from "react";

import { TwoColumnTextItemStyled, TwoColumnTextStyled } from "./styles";
import Container from "./../Container";
import Wysiwyg from "./../Wysiwyg";

interface IProps {
  firstCol: string;
  secondCol?: string;
  cssColumns?: boolean;
}

const TwoColumnText: React.FC<IProps> = ({ firstCol, secondCol, cssColumns }) => {
  return (
    <TwoColumnTextStyled flexWrap={["wrap", ""]}>
      {firstCol && (
        <TwoColumnTextItemStyled
          flex={["auto", "1"]}
          width={["100%", ""]}
          cssColumns={cssColumns}
        >
          <Container width={["100%", "100%"]} pr={cssColumns ? null : ["", "40px", "70px"]}>
            <Wysiwyg>
              <div 
                className="content"
                dangerouslySetInnerHTML={{ __html: firstCol }} 
              />
            </Wysiwyg>
          </Container>
        </TwoColumnTextItemStyled>
      )}
      {secondCol && (
        <TwoColumnTextItemStyled
          flex={["auto", "1"]}
          width={["100%", ""]}
          pb={["2.75rem", "0"]}
        >
          <Container width={["100%", "100%"]} pr={["", "20px", "50px"]}>
            <Wysiwyg>
              <div dangerouslySetInnerHTML={{ __html: secondCol }} />
            </Wysiwyg>
          </Container>
        </TwoColumnTextItemStyled>
      )}
    </TwoColumnTextStyled>
  );
};

export default TwoColumnText;
