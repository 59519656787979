import React from "react";

import {
  TimelineBlockStyled,
  TimelineBlockItemStyled,
  TimelineHeadingStyled,
  TimelineDescStyled,
  TimelineItemStyled,
  TimelineContainerStyled,
  TimelineContainerListStyled,
  TimelineContainerLineStyled,
  TimelineFooterStyled,
  TimelineListStyled,
  TimelineListItemStyled,
} from "./styles";
import Container from "./../Container";
import Wysiwyg from "./../Wysiwyg";
import Infobox from "./../Infobox";
import { Typo4 } from "./../Typography";
import { Flex, FlexItem } from "./../Flex";
import { Heading12 } from "./../Typography";

const TimelineSection = ({ heading, description, items, footerText }) => (
  <Container>
    <TimelineBlockStyled flexDirection="column">
      <TimelineBlockItemStyled pb={["80px", "0"]}>
        <Container color="white">
          <TimelineBlockStyled flexDirection={["column", "column", "row"]}>
            <TimelineBlockItemStyled
              flex={["1 0 100%", "1 0 50%"]}
              pb={["30px", "90px"]}
            >
              {heading && (
                <Container pr={["", "20px"]}>
                  <TimelineHeadingStyled>{heading}</TimelineHeadingStyled>
                </Container>
              )}
            </TimelineBlockItemStyled>
            <TimelineBlockItemStyled
              flex={["1 0 100%", "1 0 50%"]}
              pb={["0px", "90px"]}
            >
              {description && (
                <TimelineDescStyled>
                  <Wysiwyg>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </Wysiwyg>
                </TimelineDescStyled>
              )}
            </TimelineBlockItemStyled>
          </TimelineBlockStyled>
        </Container>
      </TimelineBlockItemStyled>
      <TimelineBlockItemStyled pb={["25px", "30px", "0"]}>
        <TimelineContainerStyled>
          <TimelineContainerListStyled>
            <TimelineListStyled>
              {items.map(({ heading, description, icon }) => (
                <TimelineListItemStyled key={heading}>
                  <TimelineItemStyled width={["100%", "100%"]}>
                    <Infobox {...{ heading, icon }}>
                      <Typo4
                        as="div"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </Infobox>
                  </TimelineItemStyled>
                </TimelineListItemStyled>
              ))}
            </TimelineListStyled>
          </TimelineContainerListStyled>
          <TimelineContainerLineStyled />
        </TimelineContainerStyled>
      </TimelineBlockItemStyled>
      <TimelineBlockItemStyled>
        {footerText && (
          <TimelineFooterStyled>
            <Heading12 fontWeight="bold">{footerText}</Heading12>
          </TimelineFooterStyled>
        )}
      </TimelineBlockItemStyled>
    </TimelineBlockStyled>
  </Container>
);

export default TimelineSection;
