import React from "react";
import Heading from '../../interfaces/Headings'

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import TwoColumnText from "./../../components/TwoColumnText";
import { Heading3 } from "./../../components/Typography";

interface Props { 
  block: { 
    heading: string
    leftSide: string
    rightSide: string
  }
  cssColumns?: boolean
  heading?: Heading
}

const FlexTwoColumnsText: React.FC<Props> = ({ block, heading, cssColumns }) => {
  return (
    <Container pt={["50px", "75px"]} pb={["20px", "75px"]}>
      <Inner isSmall>
        <Section
          pbHead={["20px", "32px"]}
          heading={
            <Heading3 
              as={heading ? heading : 'h2'}
              fontWeight="600"
            >
              {block.heading}
            </Heading3>
          }
        >
          { cssColumns
            ? <TwoColumnText
              firstCol={block.leftSide}
              cssColumns={true}
            />
            : <TwoColumnText
              firstCol={block.leftSide}
              secondCol={block.rightSide}
            />
          }
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexTwoColumnsText;
