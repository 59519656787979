import React from "react";

import { HeroBannerStyled, HeroBannerWrapperStyled } from "./styles";
import Image from "./../Image";

interface IProps {
  image: string;
}

const HeroBanner: React.FC<IProps> = ({ image, altText }) => (
  <>
    {image && (
      <HeroBannerStyled>
        <HeroBannerWrapperStyled>
          <Image fluid={image} alt={altText} />
        </HeroBannerWrapperStyled>
      </HeroBannerStyled>
    )}
  </>
);

export default HeroBanner;
