import styled, { css } from 'styled-components';
import { Field } from 'formik';

import { customMedia, media } from './../../common/MediaQueries';
import { transition } from './../../common/mixins';

const FormStyled = styled.form``;

const FormHeadStyled = styled.div`
  margin-bottom: 24px;
`;

const FormBodyStyled = styled.fieldset`
  display: flex;

  @media ${customMedia.maxTablet} {
    flex-direction: column;
  }
`;

const FormActionStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormColStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media ${media.phone} {
    margin: 5px;
  }
`;

const FormMsgStyled = styled.div`
  text-align: center;
  @media ${media.tablet} {
    text-align: left;
  }
`;

/// input

const FormGroupItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 5px;
  height: auto;
  max-height: 100%;
  flex-basis: 100%;
  max-width: 100%;

  @media ${media.phone} {
    flex-basis: 50%;
    max-width: 50%;
  }

  @media ${customMedia.maxTablet} {
    margin-bottom: 16px;
  }
`;

const FormFieldInputStyled = styled(Field)`
  border: none;
  width: 100%;
  //height: 60px;
  height: 46px;
  min-height: 100%;
  padding: 0 18px;
  background-color: rgba(56, 177, 235, 0.3);
  color: ${({ theme }) => theme.colors.main};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  border-radius: 6px 6px 0 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.watermelon};
  transition:
    border-color 0.2s ease,
    box-shadow 0.2s ease;
  resize: none;
  outline: 0;

  &.is-valid {
    border-bottom-color: ${({ theme }) => theme.colors.green};
  }
  &.is-invalid {
    border-bottom-color: ${({ theme }) => theme.colors.red};
  }
  &.is-invalid + label,
  &:focus + label,
  &:valid + label {
    transform: translateY(-42px);
    left: 22px;
    font-weight: 600;

    font-size: 10px;
    outline: none;

    padding: 2px 4px;
  }

  &.is-invalid + label {
    color: ${({ theme }) => theme.colors.red};
  }

  ${({ isTextArea }) =>
    isTextArea &&
    css`
      padding: 18px;
      &.is-invalid + label,
      &:focus + label,
      &:valid + label {
        transform: translateY(-40px);
      }
    `}

  @media ${media.phone} {
    font-size: 14px;
  }
`;

const FormFieldLabelStyled = styled.label`
  ${transition};
  color: ${({ theme }) => theme.colors.main};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;

  font-size: 14px;
  pointer-events: none;
`;

const FormGroupStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  height: 100%;

  @media ${media.tablet} {
    margin-bottom: 16px;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      @media ${media.phone} {
        ${FormGroupItemStyled} {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    `}
`;

const FormSubmitStyled = styled.button`
  ${transition};
  background-color: ${({ theme }) => theme.colors.watermelon};
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: -0.44px;
  text-align: center;
  padding: 10px;
  min-width: 180px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

const FormFieldErrorStyled = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const FormFieldItemStyled = styled.div`
  position: relative;
  border-radius: 6px 6px 0 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ isTextArea }) =>
    isTextArea &&
    css`
      height: 120px;
      @media ${media.tablet} {
        height: 100%;
      }
      ${FormFieldLabelStyled} {
        top: 20px;
        transform: none;
      }
    `}
`;

const FormWrapperStyled = styled.div`
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.3;
    `}

  ${({ isDarkBg }) =>
    isDarkBg &&
    css`
      ${FormFieldInputStyled} {
        background-color: rgba(1, 33, 71, 0.6);
        border-bottom-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.white};
        &.is-valid {
          border-bottom-color: ${({ theme }) => theme.colors.green};
        }
        &.is-invalid {
          border-bottom-color: ${({ theme }) => theme.colors.red};
        }
        &.is-invalid + label {
          color: ${({ theme }) => theme.colors.redLight};
        }
      }
      ${FormFieldLabelStyled} {
        color: ${({ theme }) => theme.colors.white};
      }
      ${FormFieldErrorStyled} {
        color: ${({ theme }) => theme.colors.redLight};
      }
      ${FormSubmitStyled} {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.main};
        &:hover {
          background-color: ${({ theme }) => theme.colors.watermelon};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    `}
`;

export {
  FormWrapperStyled,
  FormStyled,
  FormHeadStyled,
  FormBodyStyled,
  FormActionStyled,
  FormColStyled,
  FormMsgStyled,
  FormGroupItemStyled,
  FormFieldItemStyled,
  FormFieldInputStyled,
  FormFieldLabelStyled,
  FormGroupStyled,
  FormSubmitStyled,
  FormFieldErrorStyled,
};
