import IJob from '../interfaces/IJob';

export const youtubeParser = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

export const encode = (data: any) => {
  return Object.keys(data)
    .map((key: string) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export const richSnippetHtmlStringFormat = (string: string) => {
  return string
    .replace(/<h[^>]*>/g, '<p>')
    .replace(/<\/h[^>]>/g, '</p>')
    .replace(/<strong.*?<\/strong>/g, '')
    .replace(/<span.*?<\/span>/g, '')
    .replace(/<a.*?<\/a>/g, '')
    .replace(/<img[^>]*>/g, '')
    .replace(/<p[^>]*>/g, '<p>')
    .replace(/<li[^>]*>/g, '<li>');
};

export const openGraphUrlParser = (url: string) => {
  if (url.substr(-1) !== '/') {
    return url.concat('/').replace('//api.flatworld', '//flatworld').replace('/homepage/', '/');
  }
  return url.replace('//api.flatworld', '//flatworld').replace('/homepage/', '/');
};

export const formatSalaryString = (job: IJob) => {
  const { salaryMin, salaryMax, salaryCurrency, salaryAggregation } = job;

  const number = formatSalaryNumber(job);

  if (!salaryCurrency || !salaryAggregation) {
    return `Salary: ${number}`;
  }

  if (!salaryMin && !salaryMax) {
    return `Salary: ${number}`;
  }

  return number;
};

export const formatSalaryNumber = (job: IJob, wtihAggregation = true) => {
  const { salaryMin, salaryMax, salaryCurrency, salaryAggregation } = job;

  const currencySymbol = salaryCurrency === 'USD' ? '$' : salaryCurrency === 'EUR' ? '€' : '£';

  if (!salaryCurrency || !salaryAggregation) {
    return 'Undisclosed';
  }

  if (!salaryMin && !salaryMax) {
    return 'Undisclosed';
  }

  if (salaryMin && salaryMax) {
    return `${currencySymbol}${numberWithCommas(salaryMin)} to ${currencySymbol}${numberWithCommas(
      salaryMax
    )} ${salaryCurrency}${wtihAggregation ? ` / ${salaryAggregation}` : ''}`;
  }

  const salary = salaryMin || salaryMax;

  return `${currencySymbol}${numberWithCommas(salary!)} ${salaryCurrency}${
    wtihAggregation ? ` / ${salaryAggregation}` : ''
  }`;
};

function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const hourToIntex = (hour: number, offset: number): number => {
  return 12 + hour - offset;
};

export const indexToPercent = (index: number): number => {
  return index * 4.16666666;
};

export const pixelToPercent = (pixel: number, width: number): number => {
  return Math.max(0, Math.min(100, (pixel / width) * 100));
};

export const percentToIndex = (percent: number): number => {
  return Math.round(percent / 4.16666666);
};

export const pixelToIndex = (pixel: number, width: number): number => {
  return percentToIndex(pixelToPercent(pixel, width));
};

export const indexToHour = (index: number, offset: number) => {
  return -12 + index - offset;
};

export const testEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};
