import React from "react";

import {
  FormSectionStyled,
  FormSectionMediaStyled,
  FormSectionContentWrapperStyled,
  FormSectionContentStyled,
  FormSectionContentWrapStyled,
} from "./styles";
import Image from "./../Image";
import Inner from "./../Inner";

interface IProps {
  image?: string;
  alt?: string;
  background?: React.ReactNode;
  isFormFullWidth?: boolean;
}

const FormSection: React.FC<IProps> = ({
  image,
  alt,
  children,
  background,
  isFormFullWidth,
}) => (
  <FormSectionStyled>
    <FormSectionContentStyled>
      <Inner isSmall noOffsetMobile>
        <FormSectionContentWrapStyled>
          <FormSectionContentWrapperStyled
            {...{ isFormFullWidth }}
            bg={background}
          >
            {children}
          </FormSectionContentWrapperStyled>
          {image && (
            <FormSectionMediaStyled>
              <Image fluid={image} alt={`${alt} photo`} />
            </FormSectionMediaStyled>
          )}
        </FormSectionContentWrapStyled>
      </Inner>
    </FormSectionContentStyled>
  </FormSectionStyled>
);

export default FormSection;
