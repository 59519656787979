import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import Bios from "./../../components/Bios";

const FlexBios = ({ block, index }) => {
  return (
    <>
      {block.bios !== null ? (
        <Container pt={["50px", "70px", "110px"]}>
          <Inner isMedium>
            <Section ptBody={["", "2.5rem"]}>
              <Bios items={block.bios} />
            </Section>
          </Inner>
        </Container>
      ) : (
        ""
      )}
    </>
  );
};

export default FlexBios;
