import React from "react";
import styled from "styled-components";

import { Flex, FlexItem } from "./../Flex";
import Container from "./../Container";
import Infobox from "./../Infobox";
import { IFeature } from "../../interfaces/IFeature";
import { media } from "./../../common/MediaQueries";

import { Typo4 } from "./../Typography";

const FeaturesWrapper = styled.div`
  ${FlexItem} {
    @media ${media.phone} {
      &:last-child {
        ${Container} {
          padding-right: 0;
        }
      }
    }
  }
`;

export interface IFeatureItem {
  items: IFeature[];
}

const Features: React.FC<IFeatureItem> = ({ items }) => {
  return (
    <FeaturesWrapper>
      <Flex flexWrap={["wrap", ""]}>
        {items.map(({ heading, description, icon }) => (
          <FlexItem
            flex={["1 0 50%", "1"]}
            pb={["2.75rem", "0"]}
            key={icon.altText}
          >
            <Container width={["100%", "100%"]} pr={["", "30px"]}>
              <Infobox {...{ heading, icon }}>
                <Typo4
                  as="div"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Infobox>
            </Container>
          </FlexItem>
        ))}
      </Flex>
    </FeaturesWrapper>
  );
};

export default Features;
