import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import TimelineSection from "./../../components/TimelineSection";

const FlexTimeline = ({ block, index }) => {
  return (
    <Inner noOffsetMobile>
      <Container
        pt={["60px", "60px"]}
        pb={["90px", "80px"]}
        color="white"
        backgroundImage="linear-gradient(180deg, #012147 0%, #03457D 100%)"
      >
        <Container>
          <Inner isSmall>
            <Section>
              <TimelineSection
                heading={<>The best partners for finding the best match</>}
                description="<p>Flatworld brings you the most dedicated team of professionals with the most cutting-edge algorithm for matching optimization.</p><p>Through this powerful combination we bring you the best developers who will help take your company to new heights of success.</p>"
                items={block.iconsWithDescription}
                footerText="the best match for growth."
              />
            </Section>
          </Inner>
        </Container>
      </Container>
    </Inner>
  );
};

export default FlexTimeline;
