import React from "react";
import styled from "styled-components";

import { Flex, FlexItem } from "./../Flex";
import Container from "./../Container";
import { IBios } from "../../interfaces/IBios";
import { media } from "./../../common/MediaQueries";
import BiosItem from "./../../components/BiosItem";

const BiosWrapper = styled.div`
  ${FlexItem} {
    @media ${media.tablet} {
      &::nth-child(2n) {
        ${Container} {
          padding-right: 0;
        }
      }
    }
  }
`;

export interface IBiosItem {
  items: IBios[];
}

const Bios: React.FC<IBiosItem> = ({ items }) => {
  return (
    <BiosWrapper>
      <Flex flexWrap={["wrap", "wrap", ""]}>
        {items.map(({ fullName, description, text, image }) => (
          <FlexItem
            width={["100%", "100%", "50%"]}
            pb={["50px", "50px"]}
            key={fullName}
          >
            <Container width={["100%", "100%"]} pr={["", "80px"]}>
              <BiosItem {...{ fullName, description, text, image }} />
            </Container>
          </FlexItem>
        ))}
      </Flex>
    </BiosWrapper>
  );
};

export default Bios;
